.lxvs42t, .lxvs42t:visited {
  color: #5e93c5;
  -webkit-text-decoration: none;
  text-decoration: none;
}

.lxvs42t:hover, .lxvs42t:focus {
  color: #7eadda;
  cursor: pointer;
  outline: 0;
}

.lxvs42t:active {
  color: #4a7bab;
}

.bpsgbes {
  display: inline-block;
}

.b181v2oy {
  color: #888;
  vertical-align: bottom;
  background: #fbfbfb;
  border: 0;
  width: 36px;
  height: 34px;
  padding-top: 5px;
  font-size: 18px;
}

.b181v2oy:hover, .b181v2oy:focus {
  background: #f3f3f3;
  outline: 0;
}

.b181v2oy svg {
  fill: #888;
}

.a9immln {
  color: #444;
  background: #efefef;
}

.a9immln svg {
  fill: #444;
}

.tukdd6b {
  z-index: 2;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 2px;
  position: absolute;
  left: 50%;
  transform: translate(-50%)scale(0);
  box-shadow: 0 1px 3px #dcdcdc;
}

.tukdd6b:after, .tukdd6b:before {
  content: " ";
  pointer-events: none;
  border: solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  top: 100%;
  left: 50%;
}

.tukdd6b:after {
  border-width: 4px;
  border-color: #fff #fff0 #fff0;
  margin-left: -4px;
}

.tukdd6b:before {
  border-width: 6px;
  border-color: #ddd #ddd0 #ddd0;
  margin-left: -6px;
}

.s1o2cezu {
  border-right: 1px solid #ddd;
  height: 24px;
  margin: 0 .5em;
  display: inline-block;
}
/*# sourceMappingURL=index.fa1cfe41.css.map */
